import React, { Component } from "react";
import { graphql } from "react-apollo";
import cloudinary from "cloudinary-core";
import queryString from "query-string";

import { Button, Grid, Typography, withMobileDialog } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import BackgroundSlider from "react-background-slider";
import { loader } from "graphql.macro";
import withTranslator from "./hocs/withTranslator";
import TermsOfAdhesionSigningDialog from "./TermsOfAdhesionSigningDialog";
import Clients from "./Clients";
import Projects from "./Projects";
// import Contact from "./Contact";
import Faq from "./Faq";
// import Benefits from "./Benefits";
import HowItWorks from "./HowItWorks";
import CustomerTestimonials from "./CustomerTestimonials";
import WhoWeAre from "./WhoWeAre";
// import SavingsCalculator from "./SavingsCalculator";
import { constants } from "../global";
import client from "../apollo";

const homeQuery = loader("../queries/Home.graphql");
const brInvoiceDownloadUrlQuery = loader(
  "../queries/BrInvoiceDownloadUrl.graphql"
);
const brTermsOfAdhesionQuery = loader("../queries/BrTermsOfAdhesion.graphql");

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const mobilePadding = "1rem";
const styles = (theme) => ({});

const cl = new cloudinary.Cloudinary({
  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  secure: true,
});

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contactFormOpen: false, // eslint-disable-line
      signToaDialogOpen: false,
      toaData: null,
    };
  }

  handleDownloadInvoice(brInvoiceId, offlineDownloadKey) {
    client
      .query({
        query: brInvoiceDownloadUrlQuery,
        variables: {
          input: {
            brInvoiceId: parseInt(brInvoiceId, 10),
            includeStatement: true,
            offlineDownloadKey,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then(
        (resp) => {
          if (resp.loading) {
            return null;
          }
          if (resp.error) {
            return null;
          }
          if (!resp.data?.brInvoiceDownloadUrl) {
            return null;
          }
          const downloadLink = document.createElement("a");
          downloadLink.download = `fatura.pdf`;
          downloadLink.href = resp.data.brInvoiceDownloadUrl;
          document.body.append(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          return null;
        },
        (err) => {
          console.error(err);
        }
      );
  }

  handleTermsOfAdhesionSigning(brTermsOfAdhesionId, secretKey) {
    client
      .query({
        query: brTermsOfAdhesionQuery,
        variables: {
          input: {
            id: parseInt(brTermsOfAdhesionId, 10),
            secretKey,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then(
        (resp) => {
          if (resp.loading) {
            return null;
          }
          if (resp.error) {
            return null;
          }
          if (!resp.data?.brTermsOfAdhesion) {
            return null;
          }
          this.setState({
            toaData: resp.data.brTermsOfAdhesion,
            signToaDialogOpen: true,
          });
        },
        (err) => {
          console.error(err);
        }
      );
  }

  getBackgroundImages() {
    const { fullScreen } = this.props;
    return [
      "energea/br-images/itaguai1",
      !fullScreen
        ? "energea/br-images/iStock-1340975359-flipped"
        : "energea/br-images/iStock-1340975359-flipped-mobile", // Brazilian woman coffee shop
      "energea/homepage/homepage-new-2-flipped", // Iguatama solar site sunset
      "energea/br-images/banner-1", // city sunset
    ].map((publicId) =>
      cl.url(publicId, {
        width: "1920",
        crop: "scale",
        format: "WebP",
        quality: "auto",
      })
    );
  }

  renderAboveTheFold() {
    const { fullScreen, setSavingsCalculatorOpen, i18n } = this.props;
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="space-between"
        style={{
          height: "calc(100vh - 64px)",
          minHeight: "70vh",
          maxHeight: "100vh",
          overflow: "hidden",
          position: "relative",
          background:
            "linear-gradient(90deg, rgba(0,0,0,0.1) 40%, rgba(0,0,0,0) 100%)",
        }}
      >
        <BackgroundSlider
          images={this.getBackgroundImages()}
          duration={5}
          transition={1}
        />
        <Grid
          item
          style={{
            marginTop: fullScreen ? "4rem" : "8rem",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" style={{ height: "100%" }}>
            <Grid
              item
              container
              xs={12}
              md={10}
              lg={9}
              xl={8}
              style={{
                width: "100%",
                marginBottom: "1rem",
                padding: mobilePadding,
              }}
              alignItems={"center"}
            >
              <Grid item lg={7} md={9} xs={12}>
                <Typography
                  variant="h1"
                  style={{
                    color: "#fff",
                    zIndex: "10000",
                    textShadow: "rgba(0,0,0,1) 0 0 14px",
                  }}
                  gutterBottom
                >
                  Descubra o jeito novo de ter sua conta de luz até{" "}
                  {constants.discountRate * 100}% mais barata TODO MÊS.
                </Typography>
                <Typography
                  style={{
                    color: "#fff",
                    zIndex: "10000",
                    textShadow: "rgba(0,0,0,1) 0 0 14px",
                  }}
                  variant="h3"
                  component="p"
                  gutterBottom
                >
                  Consumir energia solar é mais barato e agrega valor
                  sustentável à sua marca e ao seu estilo de vida.
                </Typography>
                <Button
                  size="large"
                  color="secondary"
                  onClick={() => setSavingsCalculatorOpen(true)}
                  variant="contained"
                  style={{
                    marginTop: "2rem",
                    borderRadius: "50px",
                    padding: "1rem 2rem",
                    marginBottom: "2rem",
                    fontSize: "1.2rem",
                  }}
                >
                  {i18n.t("simulateSavings", "Simulate Savings")}
                </Button>
              </Grid>
              {/* <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems={!fullScreen ? "center" : "flex-start"}
                  direction={fullScreen ? "column" : "row"}
                  style={{
                    marginTop: fullScreen ? "-4rem" : undefined,
                    width: "100%",
                    height: fullScreen ? "100%" : null,
                  }}
                >
                  <Grid
                    item
                    style={{ marginBottom: fullScreen ? "1rem" : null }}
                  >
                    <Button
                      size="large"
                      color="secondary"
                      onClick={() => setSavingsCalculatorOpen(true)}
                      variant="contained"
                      style={{ borderRadius: "50px" }}
                    >
                      Simular economia
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ color: "#fff" }}>
                      <Grid
                        container
                        alignItems="center"
                        direction={fullScreen ? "row-reverse" : "row"}
                      >
                        <Grid item style={{ maxWidth: "50vw" }}>
                          <b>
                            {i18n.t(
                              "HowToContributeToAMoreSustainablePlanet",
                              "How to Contribute to a More Sustainable Planet"
                            )}
                          </b>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="small"
                            component={HashLink}
                            smooth
                            to="/#como-funciona"
                            aria-label="go to next"
                            color="primary"
                            variant="contained"
                            style={{
                              border: "4px solid rgba(255,255,255,.5)",
                              padding: 0,
                              margin: "0 .75rem",
                            }}
                          >
                            <KeyboardArrowDown
                              style={{
                                background: "#fff",
                                borderRadius: "50px",
                                padding: "2px",
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { toaData, signToaDialogOpen } = this.state;
    // const { theme, fullScreen, data } = this.props;
    const { download, fatura, key, signtoa, toaid } = queryString.parse(
      window.location.search
    );
    if (download && fatura && key) {
      this.handleDownloadInvoice(fatura, key);
    }
    if (signtoa && toaid && key && !toaData) {
      this.handleTermsOfAdhesionSigning(toaid, key);
    }

    return (
      <>
        {signToaDialogOpen && toaData && (
          <TermsOfAdhesionSigningDialog
            open={true}
            pdfUrl={toaData.pendingSignatureDownloadUrl}
            signersName={
              toaData.brConsumerUnit?.brCustomer?.primaryBrContact?.fullName
            }
            termsOfAdhesionId={toaData.id}
            secretKey={key}
          />
        )}
        {this.renderAboveTheFold()}
        <CustomerTestimonials />
        <Clients />
        <Grid container alignItems="stretch" direction="row">
          <Projects dark />
        </Grid>
        <HowItWorks />
        <WhoWeAre />
        {/* TODO: Uncomment and make the blog */}
        {/* <Grid
          container
          style={{
            padding: "1rem",
            position: "relative",
            background: "#fff",
            // background: theme.palette.primary.main,
          }}
          justifyContent="center"
        >
          <Grid item xl={8} lg={9} xs={12}>
            <Typography
              style={{ marginTop: "2rem", width: "100%" }}
              gutterBottom
              color="primary"
              variant="h2"
            >
              Fique por dentro
            </Typography>
          </Grid>
          <Grid
            style={{ position: "relative" }}
            item
            xl={8}
            lg={9}
            md={10}
            xs={12}
          >
            {loading ? (
              <Grid
                container
                alignItems="center"
                style={{ position: "relative" }}
              >
                <Grid
                  style={{
                    width: "100%",
                    position: "absolute",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress style={{ color: "#fff" }} />
                </Grid>
              </Grid>
            ) : null}
            <iframe
              title="Energea Instagram Feed"
              src="https://www.powr.io/social-feed/u/9d841a9c_1597964052#platform=iframe"
              style={{ width: "100%" }}
              height="420px"
              onLoad={() => this.setState({ loading: false })}
              frameBorder="0"
            ></iframe>
          </Grid>
        </Grid> */}
        <Faq />
      </>
    );
  }
}

export default graphql(homeQuery)(
  withStyles(styles, { withTheme: true })(
    withMobileDialog()(withTranslator(Home))
  )
);
